import React, { useEffect, useState } from 'react';
import { Wrapper, Placeholder } from './video-youtube.styled';
import { IconPlayFilled } from '../../../app/icons/play-filled';
import { GapSizeType } from '../../../../theme/type/gap-type';

type VideoYoutubeType = {
    videoId: string;
    title: string;
    placeholder: string;
    marginTop?: GapSizeType;
    marginBottom?: GapSizeType;
    autoplay?: boolean;
};

export const VideoYoutube: React.FC<VideoYoutubeType> = ({
    videoId,
    title,
    placeholder,
    marginTop = 'none',
    marginBottom = 'none',
    autoplay = false,
}: VideoYoutubeType) => {
    const [isRender, setIsRender] = useState<boolean>(false);

    useEffect(() => {
        if (autoplay) {
            setIsRender(true);
        }
    }, [autoplay]);

    return (
        <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
            {!isRender && (
                <Placeholder onClick={() => setIsRender(true)} image={placeholder}>
                    <IconPlayFilled />
                </Placeholder>
            )}
            {isRender && (
                <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title={title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
        </Wrapper>
    );
};
